import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
export default {
  name: '',
  props: {},
  data: function data() {
    return {
      showFooter: true
    };
  },
  computed: {},
  beforeRouteEnter: function beforeRouteEnter() {
    var isMobile = /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent);
    var hash = window.location.hash.slice(2);
    console.log('beforeRouteEnter', isMobile, hash); // this.showFooter = (!isMobile || (isMobile && hash === '')) ? true : false
  },
  watch: {},
  created: function created() {
    console.log(window.location.hash.slice(2) === '');
  },
  mounted: function mounted() {},
  methods: {}
};