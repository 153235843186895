import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Customized from '@/component/Customized';
export default {
  data: function data() {
    return {
      isCollapse: false,
      // 当前激活菜单的 index
      activeIndex: 'record',
      top: 0,
      navigation: true,
      country: '',
      search: '',
      content: '',
      hoverPopover: 100,
      popover: false,
      logo: require('../../assets/images/logo.jpg'),
      uCenterIcon: require('../../assets/icons/user.svg'),
      menu: [{
        name: '/',
        text: 'navigation.About special me',
        children: [{
          name: '/story',
          text: 'navigation.Brand Story'
        }, {
          name: '/press',
          text: 'navigation.Press'
        } // { name: 'faqs', text: 'navigation.FAQS' }
        ]
      }, // { name: '/record', text: 'navigation.Intelligent AI customization' },
      {
        name: '/product',
        text: 'navigation.Product introduction'
      }, {
        name: '/recommend',
        text: 'navigation.recommend'
      } // { name: '/personal', text: 'navigation.Personal Center', icon: require('../assets/icons/user.svg') }
      ],
      userInfo: '',
      test: 0
    };
  },
  components: {
    Customized: Customized
  },
  computed: {
    isMobile: function isMobile() {
      return /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent);
    },
    hadLogin: function hadLogin() {
      var _this$userInfo;

      return ((_this$userInfo = this.userInfo) === null || _this$userInfo === void 0 ? void 0 : _this$userInfo.user_sn) || false;
    },
    menuActiveList: function menuActiveList() {
      return this.menu.map(function (i) {
        return i.name;
      });
    }
  },
  watch: {
    '$route': function $route(to, form) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || '';
      var menuActiveList = this.menuActiveList;
      var path = to.path.split('/')[1];
      console.log(path, menuActiveList, this.userInfo);

      if (menuActiveList.indexOf('/' + path) === -1) {
        this.activeIndex = null;
      } else {
        this.activeIndex = to.path;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.country = localStorage.getItem('country') || 'en';
    this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || '';
    window.addEventListener('click', function (e) {
      if (_this.isCollapse) {
        _this.isCollapse = false;
      }
    });
  },
  beforeDestroy: function beforeDestroy() {// 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
    // window.removeEventListener("scroll", this.handleScroll0);
  },
  methods: {
    handleOpen: function handleOpen() {},
    handleClose: function handleClose() {},
    // logo click
    handleLogoClick: function handleLogoClick() {
      var current = location.hash.slice(2);

      if (current) {
        this.$router.push({
          path: "/"
        });
      } else {
        document.documentElement.scrollTop = 0;
      }
    },
    // 导航栏菜单激活回调
    handleSelect: function handleSelect(index, path) {
      this.activeIndex = index;
      console.log('handleSelect', index, path, this.activeIndex);
    },
    // 解决导航栏不能滚动的问题
    handleScroll0: function handleScroll0() {
      var sl = -Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
      document.getElementById('fixed').style.left = sl + 'px';
      var scrollTop = window.pageYOffset;
      this.navigation = scrollTop <= this.top;
      this.top = scrollTop;
    },
    language: function language() {
      if (this.country == 'zh') {
        this.country = 'en';
        this.$i18n.locale = 'en';
      } else {
        this.country = 'zh';
        this.$i18n.locale = 'zh';
      }

      this.$boss.$emit('langer', this.country);
      localStorage.setItem('country', this.country);
      window.location.reload();
    }
  }
};