//
//
//
//
//
//
//
//
import Layout from '@/layout/index';
import 'animate.css';
export default {
  components: {
    Layout: Layout
  },
  data: function data() {
    return {
      default_layout: 'default'
    };
  },
  computed: {
    layout: function layout() {
      return (this.$route.meta.layout || this.default_layout) + '-layout';
    }
  },
  created: function created() {
    localStorage.setItem('country', 'en');
  },
  methods: {}
};