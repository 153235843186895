import _defineProperty from "/home/wwwroot/specialmepc/specialmepc/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return _defineProperty({
      about: [],
      contact: [],
      help: [],
      // image.png
      community: [require('../../assets/icons/ins.jpeg'), require('../../assets/icons/facebook.jpeg'), require('../../assets/icons/Pinterest.jpeg'), require('../../assets/icons/douyin.svg'), require('../../assets/icons/youtube.jpeg')],
      brand: [require('../../assets/icons/weixin.svg'), require('../../assets/icons/weibo.svg'), require('../../assets/icons/zhifubao.svg'), require('../../assets/icons/douyin.svg'), require('../../assets/icons/qq.svg')]
    }, "contact", [{
      key: '联系电话',
      value: '021-88888888'
    }, {
      key: '联系邮箱',
      value: '021-88888888',
      link: 'contactus'
    }, {
      key: '联系地址',
      value: '021-88888888'
    }]);
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取首页数据
    getData: function getData() {
      var _this = this;

      this.$http.post('/index/index/PcFooter', {}, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(function (res) {
        if (res.code == 0) {
          // this.about = res.data.about
          _this.contact = res.data.contact;
          _this.help = res.data.help;
        }
      });
    },
    // 帮助详情
    toDetail: function toDetail(item) {
      var jump_url = item.jump_url,
          id = item.id;

      if (jump_url) {
        window.location.href = jump_url;
      } else {
        this.$router.push({
          name: 'detail',
          query: {
            id: id
          }
        });
      }
    },
    // 协议类详情
    toTerms: function toTerms(type) {
      this.$router.push({
        name: 'terms',
        query: {
          type: type
        }
      });
    }
  }
};